import { Heading } from '@msdyn365-commerce-modules/utilities';
import { ICookieValue, RichTextComponent } from '@msdyn365-commerce/core';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { IPromoBannerCookieData } from './promo-banner-cookie.data';
import { ILinkData, IPromoBannerCookieProps } from './promo-banner-cookie.props.autogenerated';

// =========================================================================
// MODULE-WIDE CONSTANTS
// =========================================================================
const CookieName = 'promoBannerCookie';

/**
 *
 * PromoBannerCookie component
 * @extends {React.Component<IPromoBannerCookieProps<IPromoBannerCookieData>>}
 */
@observer
class PromoBannerCookie extends React.Component<IPromoBannerCookieProps<IPromoBannerCookieData>> {

    // ==========================================================================
    // OBSERVABLES
    // ==========================================================================

    @observable private bannerDismissed: boolean = false;

    // ------------------------------------------------------
    // ------------------------------------------------------
    constructor(props: IPromoBannerCookieProps<IPromoBannerCookieData>) {
        super(props);
        this._closeModal = this._closeModal.bind(this);
    }

    public componentDidMount(): void {
        if (this.props.config.bodyClass && (!this._isCookieSet || this.bannerDismissed === false)) {
            document.body.classList.add(this.props.config.bodyClass);
        }
    }

    public render(): JSX.Element | null {

        // tslint:disable-next-line: no-typeof-undefined
        if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
            return null;
        }

        const { config } = this.props;
        const cookie = this.props.context.request.cookies;

        try {
            cookie.setConsentCookie();
        } catch (error) {
            console.log(error);
        }

        if (this._isCookieSet === true || this.bannerDismissed === true) { return null; }
        return (
            <div
                className={`promo-banner-cookie-container ${config.className}`}
                style={{ backgroundColor: config.backgroundColor }}
            >
                {this._renderContent}
                {this._renderButton(config.dismissible!)}
            </div>
        );
    }

    // =========================================================================
    // PRIVATE METHODS
    // =========================================================================

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _setCookie(): void {
        const cookieMaxAge = this.props.config.cookieMaxAge ? this.props.config.cookieMaxAge * 60 * 60 : 86400;
        this.props.context.request.cookies.set(CookieName, true, { path: '/', maxAge: cookieMaxAge });
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private get _isCookieSet(): boolean {
        const cookie = this.props.context.request.cookies;
        const promoBannerCookie = cookie.get(CookieName) as ICookieValue<{}> | undefined;

        if (promoBannerCookie === undefined || promoBannerCookie.value === 'false') { return false; }

        return (!!promoBannerCookie.value);
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _closeModal(): void {
        this._setCookie();
        if (this.props.config.bodyClass) {
            document.body.classList.remove(this.props.config.bodyClass);
        }
        this.bannerDismissed = true;
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _target(link: ILinkData): string | undefined {
        return (link.openInNewTab) ? '_blank' : undefined;
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private get _renderContent(): JSX.Element {
        const { config } = this.props;
        if (config.link && config.link.linkUrl.destinationUrl) {
            return (
                <a
                    className='promo-banner-cookie-content'
                    href={config.link.linkUrl.destinationUrl}
                    target={this._target(config.link)}
                    aria-label={config.link.ariaLabel}
                    style={{ color: config.textColor }}
                >
                    {this._renderTitle}
                    <RichTextComponent text={config.text as string} />
                </a>
           );
        } else {
            return (
                <div
                    className='promo-banner-cookie-content'
                    style={{ color: config.textColor }}
                >
                    {this._renderTitle}
                    <RichTextComponent text={config.text as string} />
                </div>
            );
        }
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private get _renderTitle(): JSX.Element | null {
        const title = this.props.config.title;
        if (!title) { return null; }
        if (!title.text) { return null; }
        return (
            <Heading
                className='promo-banner-cookie-heading'
                headingTag={title.tag}
                text={title.text}
            />
        );
    }

    // ------------------------------------------------------
    // ------------------------------------------------------
    private _renderButton(dismissible: boolean): JSX.Element | null {
        if (!dismissible) { return null; }

        return <button style={{ color: this.props.config.textColor }} className='promo-banner-cookie-close' aria-label='Close (X)' onClick={this._closeModal} />;

    }
}

export default PromoBannerCookie;
